.snackbar-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .snackbar-container {
    width: 701px;
    box-shadow: 0 2px 8px 0 #1a273d40;
    border: solid 1px #e0e7ff;
    background-color: #ffffff;
    padding: 1rem 2rem;
    margin: 0 0.5rem;
  }
  
  .error-container {
    border-left: 4px solid red;
  }
  
  .info-container {
    border-left: 4px solid #2b89e9;
  }
  
  .success-container {
    border-left: 4px solid #4cd16d;
  }
  
  .warning-container {
    border-left: 4px solid #ffbc22;
  }
  
  .snackbar-container > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .snackbar-info-container {
    display: flex;
    align-items: center;
  }
  
  .snackbar-info-container > div > h5:nth-child(1) {
    margin: 0 0 0.5rem 0;
  }

  .snackbar-info-container > div > h5:nth-child(2) {
    margin: 0;
  }
  
  .snackbar-container > div > div:nth-child(1) {
    margin-right: 1rem;
  }
  
  .snackbar-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-image: url(../images/alertCircle.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 1rem;
  }
  
  .error-snackbar-icon {
    background-color: red;
  }
  
  .info-snackbar-icon {
    background-color: #2b89e9;
  }
  
  .success-snackbar-icon {
    background-color: #4cd16d;
  }
  
  .warning-snackbar-icon {
    background-color: #ffbc22;
  }
  
  #close-snackbar-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  
  #snackbar-fixed-container {
    position: fixed;
    top: 4rem;
  }

  @font-face {
    font-family: "RubikMedium";
    src: local("RubikMedium"), url(../fonts/Rubik-Medium.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "RubikRegular";
    src: local("RubikRegular"), url(../fonts/Rubik-Regular.ttf) format("truetype");
  }

  .rubik-text {
    font-family: "RubikMedium";
    font-size: 15px;
    font-weight: 500;
    color: #2e384d;
  }
  
  .muted-rubik-text {
    font-family: "RubikRegular";
    font-size: 15px;
    color: #2e384dce;
  }
  
  @media (max-width: 728px) {
    .snackbar-container {
      width: auto;
    }
  }