
.logo {
  height: 150px;
}
.form-group {
  margin-bottom:10px;
}
.App {
  text-align: center;
}
.signin-form {
  margin: 0 auto;
  float: none; 
  text-align: center;
}

.brdr {
  border: 1px solid red;
}

.title {
  color: #888;
  font-size: 1.5em;
}

.signout-button {
  margin-right:20px;
}

.margin-bottom-10 {
  margin-bottom:10px;
}

.rule-table {
  margin-bottom:100px;
}

.rule-table td {
  word-wrap: break-word;
  max-width: 4rem;
}

.icon {
  cursor: pointer;
}

.pagination-wrapper {
  overflow-x:auto;
}

.pagination {
  justify-content: center;
  margin:30px;
}

.data-slice {
  margin-top: 15px;
}
